import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

import { MEDIA_BREAKPOINT_MG } from '../../utilities/breakpoints'
import { cl } from '../../utilities/cl'

import ButtonCircle from '../ButtonCircle'

import SvgSearch from '../SVG/Search'
import SvgClose from '../SVG/Close'


const Search = props => {
    let {
        className = "",
        placeholder = "",
        onChange,
        disabled = false,
        clearMarker,
        onSubmit,
        title
    } = props
    const {
        searchType,
        searchState,
        actions
    } = props

    const inputWrap = React.createRef()

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Останавливаем дефолтное поведение формы
            if (typeof onSubmit === "function")
                onSubmit(searchState.value);
        }
    };

    const SearchClick = searchType => {
        (searchState.modeActive)
            ? console.log("Поиск активен")
            : actions.modeActiveOn(searchType)

        if (typeof onSubmit === "function" && searchState.modeActive)
            onSubmit(searchState.value);


    }

    function hChange(e) {
        const value = e.target.value


        actions.changeValue({
            value,
            type: searchType,
        })

        if (typeof onChange === "function")
            onChange(value)
    }

    function activeOn() {
        if (window.outerWidth > MEDIA_BREAKPOINT_MG) {
            actions.modeActiveOn(searchType)
        }
        // SearchClick(searchType)

    }

    function onCloseClicked() {
        actions.modeActiveOff(searchType)
        try {
            if (searchState.value.length !== 0) {
                actions.changeValue({
                    type: searchType,
                    value: ''
                })

                if (typeof onChange === "function")
                    onChange("")

                if (typeof onSubmit === "function")
                    onSubmit("");
            }
        } catch (e) {

        }


    }


    className = cl(
        className,
        "Search",
        { "active": searchState.modeActive }
    )

    return (
        <div className={className}>

            <fieldset disabled={disabled}>
                <Form.Group className="light italic icon">
                    <div ref={inputWrap} className="Search__wrap">
                        <Form.Control
                            id="search-input"
                            type="text"
                            placeholder={placeholder}
                            value={searchState.value}
                            onChange={hChange}
                            onFocus={activeOn}
                            title={title}
                            onKeyDown={handleKeyDown}
                        />
                        <SvgSearch />
                    </div>

                    <ButtonCircle className="SvgSearch" onClick={SearchClick} value={searchType}>
                        <SvgSearch />
                    </ButtonCircle>

                    <ButtonCircle className="SvgClose" onClick={onCloseClicked} value={searchType}>
                        <SvgClose />
                    </ButtonCircle>

                </Form.Group>
            </fieldset>
        </div>
    )
}

Search.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    searchType: PropTypes.string,
    searchState: PropTypes.object,
    actions: PropTypes.object
}

export default Search