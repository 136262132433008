import React, { useState } from "react";

const Tabs = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <div style={styles.container}>
                <div style={styles.nav}>
                    {tabs.map((tab, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveTab(index)}
                            style={{
                                ...styles.tabButton,
                                ...(activeTab === index ? styles.activeTab : {}),
                            }}
                        >
                            {tab.title}
                        </button>
                    ))}
                </div>
                <div style={styles.tabContent}>{tabs[activeTab].content}</div>
            </div>
            {tabs[activeTab].afterContent}
        </>

    );
};

// Стили в виде объекта
const styles = {
    container: {
        width: "100%",
        margin: "auto",
    },
    nav: {
        display: "flex",
        borderBottom: "2px solid #ddd",

        // paddingBottom: "10px",
    },
    tabButton: {
        flex: 1,
        padding: "10px 20px",
        fontSize: "1rem",
        fontWeight: "500",
        textTransform: "uppercase",
        cursor: "pointer",
        background: "#f6f6f8",
        border: "none",
        outline: "none",
        transition: "0.3s"
    },
    activeTab: {
        color: "#ff3347",
        background: "#fff",
        borderBottom: "2px solid #ff3347",
    },
    tabContent: {
        padding: "20px",
        paddingRight: '0px',
        backgroundColor: "#fff",
        border: "1px solid #ddd",
        marginTop: "-1px",
    },
};

export default Tabs;
