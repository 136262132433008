import React from 'react'
import PropTypes from 'prop-types'

import ScrollContainer from '../../ScrollContainer'
import { cl } from '../../../utilities/cl'
import { compareArrays, compareAny } from '../../../utilities/compare'

import SvgClose from '../../SVG/Close'


class Options extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            opened: false,
            options: [],
            selected: {
                values: [],
                title: "",
            },
            searchWord: ""
        }

        this.contentRef = React.createRef()
        this.inputRef = React.createRef()

        this.inputInFocus = false

        this.hClick = this.hClick.bind(this)
        this.hKeyUp = this.hKeyUp.bind(this)
        this.hFocus = this.hFocus.bind(this)
        this.hBlur = this.hBlur.bind(this)

        this.clickItem = this.clickItem.bind(this)
        this.updateSelected = this.updateSelected.bind(this)
        this.updateOptions = this.updateOptions.bind(this)
        this.addSelected = this.addSelected.bind(this)
        this.deleteSelected = this.deleteSelected.bind(this)
        this.applyOnChange = this.applyOnChange.bind(this)

        this.hChange = this.hChange.bind(this)
        this.updateSearch = this.updateSearch.bind(this)
        this.filterOptions = this.filterOptions.bind(this)
        this.parseInputValue = this.parseInputValue.bind(this)
        this.getInputValue = this.getInputValue.bind(this)

        this.getItemHTML = this.getItemHTML.bind(this)
        this.openedControl = this.openedControl.bind(this)
        this.excludeMultiple = this.excludeMultiple.bind(this)

        this.cursorToEnd = this.cursorToEnd.bind(this)

        this.closeListener = false
        this.closeElement = this.closeElement.bind(this)
    }

    componentDidMount() {
        this.updateOptions(this.props.options)
        //this.updateSelected(this.props.selected, true)
        this.updateSearch({ value: this.props.searchWord })
    }

    componentDidUpdate(prevProps) {
        if (!compareArrays({
            prev: prevProps.options,
            next: this.props.options,
        })) {
            this.updateOptions(this.props.options, true)
        }
        if (!compareAny({
            prev: prevProps.selected,
            next: this.props.selected,
        })) {
            this.updateSelected(this.props.selected, true)
        }

        if (prevProps.searchWord !== this.props.searchWord) {
            this.updateSearch({ value: this.props.searchWord })
        }
    }

    hClick() {
        this.openedControl()
        this.cursorToEnd()
    }

    hKeyUp(e) {
        if (e.keyCode === 37 || e.keyCode === 39) {
            e.preventDefault()
            this.cursorToEnd()
        }
    }

    hFocus() {
        this.inputInFocus = true
    }

    hBlur() {
        this.inputInFocus = false
    }

    updateOptions(options = []) {
        this.setState({ options }, () => this.updateSelected(this.props.selected, true))
    }

    updateSelected(selected, mounted, empty) {
        const options = this.props.options || []
        const multiple = this.props.multiple
        let updated = false

        if (selected === empty) return

        if (!Array.isArray(selected)) selected = [selected]

        this.setState(state => {
            let prevValues = state.selected.values,
                nextValues = selected.map(value => tryToNumber(value)),
                nextTitle = []

            if (multiple) {
                prevValues.sort()
                nextValues.sort()
                if (prevValues.join(",") !== nextValues.join(",")) {
                    updated = true
                }
            } else {
                if (prevValues[0] !== nextValues[0]) {
                    updated = true
                    nextValues = [nextValues[0]]
                }
            }

            // if(updated) {
            options.map(option => {
                if (nextValues.indexOf(tryToNumber(option.value)) !== -1 && typeof option.title !== undefined && option.title !== "")
                    nextTitle.push(option.title)
            })
            //console.log(prevValues, nextValues, nextTitle)
            nextValues = nextValues.map(value => tryToNumber(value))
            nextTitle = nextTitle.join(", ")

            return {
                selected: {
                    values: nextValues,
                    title: nextTitle,
                }
            }
            // }
        }, () => this.applyOnChange(updated, mounted))
    }

    addSelected({ value, title }) {
        const multiple = this.props.multiple
        let updated = false
        value = tryToNumber(value)

        this.setState(state => {
            let nextValues = state.selected.values,
                nextTitle = state.selected.title

            if (multiple) {
                if (nextValues.indexOf(value) === -1 || nextTitle.indexOf(title) === -1) {
                    updated = true
                    nextValues.push(value)
                    nextTitle =
                        nextTitle.length
                            ? `${nextTitle}, ${title}`
                            : title
                }
            } else {
                if (nextValues[0] !== value || nextValues.length > 1) {
                    updated = true
                    nextValues = [value]
                    nextTitle = title
                }
            }

            if (updated) {
                return {
                    selected: this.excludeMultiple(nextValues, nextTitle)
                }
            }
        }, () => this.applyOnChange(updated))
    }

    deleteSelected({ value, title }, reset = false) {
        const multiple = this.props.multiple
        const options = this.props.options || {}
        let updated = false
        value = tryToNumber(value)

        this.setState(state => {
            let prevValues = state.selected.values,
                prevTitle = state.selected.title,
                nextValues = [],
                nextTitle = []

            if (!multiple || reset) {
                if (prevValues.length || prevTitle.length) {
                    updated = true
                    return {
                        selected: {
                            values: reset ? [value] : [],
                            title: reset ? title : ""
                        }
                    }
                }
            } else {
                if (prevValues.indexOf(value) !== -1 || prevTitle.indexOf(title) !== -1) {
                    updated = true
                    nextValues = prevValues.filter(val => val !== value)
                    options.map(option => {
                        if (nextValues.indexOf(tryToNumber(option.value)) !== -1)
                            nextTitle.push(option.title)
                    })

                    nextTitle = nextTitle.join(", ")

                    return {
                        selected: {
                            values: nextValues,
                            title: nextTitle
                        }
                    }
                }
            }
        }, () => this.applyOnChange(updated))

    }

    applyOnChange(updated, mounted) {
        if (updated && mounted !== true) {
            const multiple = this.props.multiple
            const onChange = this.props.onChange
            const selected = this.state.selected
            const name = this.props.name

            if (typeof onChange === "function") {
                if (multiple) {
                    onChange({
                        name,
                        value: [...new Set(selected.values)],
                        title: selected.title
                    })
                } else {
                    onChange({
                        name,
                        value: selected.values[0],
                        title: selected.title
                    })
                }
            }
        }
    }

    open() {        
        this.setState({ opened: true }, () => {
            this.inputRef.current.focus();
            this.addCloseListener();
        });
    }
    
    addCloseListener() {
        if (!this.closeListener) {
            this.closeListener = true;
            document.addEventListener("click", this.closeElement);
        }
    }
    
    openedControl() {
        if (!this.state.opened) {
            this.setState({ opened: true }, this.addCloseListener);
        }
    }

    clickItem(e) {
        const multiple = this.props.multiple
        const target = e.target
        const option = target.closest(".FormSelectOptions__item")
        const optionDelete = target.closest(".delete")

        if (option) {
            const isMultiple = option.dataset.isMultiple === "true" ? true : false
            const eventData = {
                value: option.dataset.value,
                title: option.dataset.title,
            }

            if (multiple) {
                (optionDelete || isMultiple)
                    ? this.deleteSelected(eventData, isMultiple)
                    : this.addSelected(eventData)
            } else {
                this.addSelected(eventData)
            }
        }
    }

    hChange(e) {
        const search = this.props.search
        const input = e.target

        if (search && input) {
            const value = input.value
            this.updateSearch({ value })
        }
    }

    updateSearch({ value }) {
        const { searchWord } = this.parseInputValue(value)
        const options =
            (searchWord.length)
                ? this.filterOptions(searchWord)
                : this.props.options

        this.setState({
            searchWord,
            options
        })

        if (typeof this.props.onChangeInput === "function") {
            this.props.onChangeInput(searchWord)
        }
    }

    filterOptions(word) {
        const options = this.props.options
        word = word.toLowerCase()

        return options.filter(option => {
            if (option.title && typeof option.title === "string") {
                if (option.title.toLowerCase().indexOf(word) !== -1)
                    return true
            }
            if (option.subtitle && typeof option.subtitle === "string") {
                if (option.subtitle.toLowerCase().indexOf(word) !== -1)
                    return true
            }
            return false
        })
    }

    parseInputValue(inputValue) {
        const search = this.props.search

        if (search) {
            const selectedTitle = this.state.selected.title
            const searchWord =
                (selectedTitle.length)
                    ? inputValue
                        .replace(`${selectedTitle}, `, "")
                        .replace(`${selectedTitle},`, "")
                        .replace(`${selectedTitle}`, "")
                    : inputValue

            return {
                selectedTitle: selectedTitle,
                searchWord: searchWord,
            }
        } else {
            return {
                selectedTitle: this.state.selected.title,
                searchWord: "",
            }
        }
    }

    getInputValue() {
        const search = this.props.search
        const selectedTitle = this.state.selected.title || ""
        const searchWord = this.state.searchWord

        if (search) {
            if (this.state.opened) {
                return (selectedTitle.length)
                    ? `${selectedTitle}, ${searchWord}`
                    : searchWord
            } else {
                return selectedTitle
            }
        } else {
            return selectedTitle
        }
    }

    cursorToEnd() {
        if (this.props.search) {
            const input = this.inputRef.current
            const inputLength = input.value.length
            const minCursorPosition = inputLength - this.state.searchWord.length

            const currCursorPosition = input.selectionStart

            if (currCursorPosition < minCursorPosition) {
                input.setSelectionRange(minCursorPosition, minCursorPosition)
            }
        }
    }

    render() {
        const {
            id = "",
            name = "",
            placeholder = "",
            multiple,
            resetMultiple,
            search = false,
            disabled,
        } = this.props
        const {
            options = [],
            selected = {},
            opened,
        } = this.state

        const inputClassName = cl(
            {
                "FormSelectOptions__input": [
                    { "search": search },
                ]
            },
            "form-control",
            { "active": selected?.title?.length }
        )

        const contentClassName = cl(
            {
                "FormSelect__content": [
                    { "multiple": multiple },
                    { "width-reset": () => (resetMultiple) ? true : false },
                    { "search": search },
                ]
            },
            { "opened": opened }
        )

        let inputReadonlyParam = {}
        if (!search) inputReadonlyParam.readOnly = true

        return (
            <>
                <input
                    ref={this.inputRef}
                    type="text"
                    className={inputClassName}
                    placeholder={placeholder}
                    value={this.getInputValue()}
                    onClick={this.hClick}
                    disabled={disabled}
                    onChange={this.hChange}
                    onKeyUp={this.hKeyUp}
                    onFocus={this.hFocus}
                    onBlur={this.hBlur}
                    {...inputReadonlyParam}
                />

                <div
                    ref={this.contentRef}
                    className={contentClassName}
                    onClick={this.clickItem}
                >
                    {(multiple && resetMultiple)
                        && this.getItemHTML({
                            option: resetMultiple,
                            isMultiple: true,
                            selected,
                            multiple,
                        })
                    }
                    <ScrollContainer size="md" collapsed={false}>
                        {options.length
                            ? options.map(option =>
                                this.getItemHTML({
                                    option,
                                    isMultiple: false,
                                    selected,
                                    multiple,
                                })
                            )
                            : <div className="FormSelectOptions__empty">
                                Опций нет.
                            </div>
                        }
                    </ScrollContainer>
                </div>

                <select id={id} name={name} value={selected.value}>
                    {options.map(option =>
                        <option
                            key={option.value}
                            value={option.value}
                            selected={selected.values.indexOf(option.value) !== -1}
                        >
                            {option.title}
                        </option>
                    )}
                </select>
            </>
        )
    }

    getItemHTML({ option, selected, multiple, isMultiple }) {
        const isSelected = selected.values.indexOf(option.value) !== -1
        const className = cl(
            "FormSelectOptions__item",
            { "selected": isSelected },
            { "multiple": isMultiple },
            { "underlined": !!option.note}
        )

        return (
            <div
                key={option.value}
                className={className}
                data-is-multiple={isMultiple}
                data-value={option.value}
                data-title={option.title}
                title={option.note}
            >
                {option.title}
                {(multiple && !isMultiple && isSelected)
                    && <SvgClose
                        className="delete"
                        title="Исключить"
                        size="xs1"
                        variant="dark"
                    />
                }
                {option.subtitle
                    && <div className="subtitle">
                        {option.subtitle}
                    </div>
                }
            </div>
        )
    }

    excludeMultiple(values, title) {
        const resetMultiple = this.props.resetMultiple
        if (resetMultiple) {
            return {
                values: values.filter(value => tryToNumber(resetMultiple.value) !== value),
                title: title.replace(`${resetMultiple.title}, `, "")
                    .replace(`, ${resetMultiple.title}`, "")
                    .replace(resetMultiple.title, "")
            }
        } else {
            return {
                values,
                title
            }
        }
    }

    openedControl() {
        if (!this.closeListener) {
            this.setState({
                opened: true
            })
            if (!this.state.opened) {
                this.closeListener = true
                document.addEventListener("click", this.closeElement)
            }
        }
    }

    closeElement(e) {
        const input = this.inputRef.current
        const content = this.contentRef.current
        const target = e.target
        const option = e.target.closest(".FormSelectOptions__item")
        const deleteOption = e.target.closest(".delete")
        const isInsideElement = content?.contains(target) ? true : false
        const multiple = this.props.multiple
        const isOptionMultipleReset = target.dataset.isMultiple === "true" ? true : false

        const close = () => {
            this.closeListener = false
            this.setState({
                opened: false
            })
            document.removeEventListener("click", this.closeElement)
        }

        if (target !== input && target !== content && !isInsideElement && !deleteOption) {
            return close()
        } else {
            if (!multiple && target === option)
                return close()
            if (multiple && isOptionMultipleReset)
                return close()
        }
    }
}

Options.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    // options: PropTypes.exact({
    //     title: PropTypes.string,
    //     subtitle: PropTypes.string,
    //     value: PropTypes.oneOfType([
    //         PropTypes.string,
    //         PropTypes.number
    //     ]),
    // }),
    selected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    resetMultiple: PropTypes.bool,
    multiple: PropTypes.exact({
        title: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
    }),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export default Options

//
// Функции
//

export function tryToNumber(value) {
    if (value === "") return value
    const number = Number(value)
    return isNaN(number) ? value : number
}