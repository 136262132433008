import { takeEvery, call, put, select } from 'redux-saga/effects'
import { addTagsObject, INSERT_NOTIFICATIONS_TAGS, LOAD_NOTIFICATION_HISTORY, loading, putNotificationHistory, putNotifications, putTags } from '../../store/Notifications/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

const mock_history_data = Array(100).fill({
    ms_id: 3914,
    device: "4921fd1d5f99db0a",
    status: 'status success',
    error: 1,
    date: 0
})


export default function* () {
    yield takeEvery(LOAD_NOTIFICATION_HISTORY, worker)
}

function* worker({ payload }) {
    console.log({ payload });

    try {
        const objects = yield call(fetchObjects)
        let history;
        try {
            history = yield call(fetchHistory)

        } catch (e) {
            
        }



        if (objects.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_NOTIFICATION_HISTORY}.`, objects],
                text: "История уведомлений не былаЗагружена. Попробуйте перезагрузить страницу."
            }))
        } else {
            let objectsName = {}



            objects.success.forEach(element => {
                { objectsName[element.id] = element.name }
            });
            yield put(putNotificationHistory({ objects: objectsName, list: history.success }))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_NOTIFICATION_HISTORY}.`, e],
            text: "История уведомлений не былаЗагружена. Попробуйте перезагрузить страницу."
        }))
    }
    finally {
    }
}

function fetchObjects() {

    return axios.get("/msbox/select?hidden=NULL&column[]=id&column[]=name")
        // return axios.get("/msbox/select")
        .then(response => response.data)
}

function fetchHistory() {

    // return axios.get("/msbox/select?hidden=NULL&column[]=id&column[]=name")
    return axios.get("/push/history/select")
        .then(response => response.data)
}




