import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { generateStateKey } from '../../../../../utilities/generateStateKey'
import { cl } from '../../../../../utilities/cl'

import { OBJECT_OBJECT } from '../../../../../store/Settings/sets'
import Settings from '../../../../../components/Settings'
import ButtonCircle from '../../../../../components/ButtonCircle'
import SVGPlus from '../../../../../components/SVG/Plus'
import SvgTrash from '../../../../../components/SVG/Trash'
import SvgSave from '../../../../../components/SVG/Save'


import InputText from '../../../../../components/Form/InputText'
import { Button as ButtonBS } from 'react-bootstrap'
import Button from "../../../../../components/Button"
import SVGTrash from '../../../../../components/SVG/Trash'
import Tags from '../../../../../components/Tags'
import { addNotificationsTags, deleteNotificationsTags, updateNotificationsTags, loadNotifications, updateNotifications, loadNotificationHistory } from '../../../../../store/Notifications/actions'
import Spinner from '../../../../../components/Spinner'
import SearchSelect from '../../../../../components/SearchSelect'
import Select from '../../../../../components/Form/Select'
import loadNotificationsTags from '../../../../../sagas/Notifications/addNotificationsTags'
import { loadRoles } from '../../../../../store/Roles/actions'
import { tryToNumber } from '../../../../../components/Form/Select/Options'
import Tabs from '../../../../../components/Tabs'
import moment from 'moment'


const SettingsNotifications = ({
    className = "",
    loadNotifications,
    loadRoles,
    notifyto1 = "",
    notifyto2 = "",
    loading,
    roles: rolesInner,
    currentList,
    tags: tagsInner,
    notifications = {},
    user,
    loadNotificationHistory,
    updateNotifications,
    addNotificationsTags,
    deleteNotificationsTags,
    updateNotificationsTags,
    history
}) => {


    useEffect(() => {
        loadRoles()
        loadNotifications()
        loadNotificationsTags()
        loadNotificationHistory()
    }, [])
    const [email_warn, setEmailWarn] = useState("")
    const [email_notify, setEmailNotify] = useState("")
    const [updatedList, setUpdatedList] = useState(currentList)
    const needUpdate = JSON.stringify(currentList) !== JSON.stringify(updatedList)

    useEffect(() => {
        setUpdatedList(currentList)
    }, [currentList])

    const [newObject, setNewObject] = useState({ owner_id: null, tags: [] })

    const usedRoles = updatedList.map(el => el.owner_id);
    const tags = tagsInner.map(el => ({ title: el, value: tryToNumber(el) }));
    const rolesMap = rolesInner.reduce((acc, el) => {
        acc[el.id] = { title: el.name, value: el.id };
        return acc;
    }, { [user.id]: { value: user.id, title: user.name } });
    console.log({ rolesMap });

    const roles = Object.values(rolesMap)

    const selectableRoles = Object.values(roles)
        .filter(el => usedRoles.indexOf(el.value) === -1)


    className = cl(
        className,
        "SettingsObjectObjectCollapsible"
    )
    const hChangeEmail = (e) => {
        if (e.name === "email_warn") {
            setEmailWarn(e.value)
        }
        if (e.name === "email_notify") {
            setEmailNotify(e.value)
        }
    }

    const hDeleteNotify = (e, type) => {
        if (type === "warn") {
            let email_string
            let email_arr = notifyto1.split(',')
            email_arr.splice(e.id, 1)
            email_string = email_arr.join(',')
            updateNotifications({ notifyto1: email_string })
            setEmailWarn('')
        } else if (type === "notify") {
            let email_string
            let email_arr = notifyto2.split(',')
            email_arr.splice(e.id, 1)
            email_string = email_arr.join(',')
            updateNotifications({ notifyto2: email_string })
            setEmailNotify('')
        }
    }

    const hUpdateNotify = (type) => {
        if (type === "warn") {
            let email_string
            let email_arr = []
            if (notifyto1 !== "" && notifyto1 !== null) {
                email_arr = notifyto1.split(',')
                email_arr.push(email_warn)
                email_string = email_arr.join(',')
            } else {
                email_string = email_warn
            }
            updateNotifications({ notifyto1: email_string })
            setEmailWarn('')
        } else if (type === "notify") {
            let email_string
            let email_arr = []
            if (notifyto2 !== "" && notifyto2 !== null) {
                email_arr = notifyto2.split(',')
                email_arr.push(email_notify)
                email_string = email_arr.join(',')
            } else {
                email_string = email_notify
            }
            updateNotifications({ notifyto2: email_string })
            setEmailNotify('')
        }

    }
    return (
        <div>
            <h2>НАСТРОЙКА АДРЕСОВ И УВЕДОМЛЕНИЙ</h2>

            {!loading && (Object.values(notifications).length > 0 || Object.values(notifications).length === 0) ?
                <div className="Notifications">
                    <div className="Notifications_settings_mail">
                        <div className="Notifications_settings_mail_element">
                            <div className='Notifications_settings_mail_element_tags'>
                                <h4>Предупреждение о сбое синхронизации плеера:</h4>
                                <div className='Notifications_settings_mail_element_tags_items'>
                                    <Tags
                                        tags={notifyto1 !== "" && notifyto1 !== null && [...notifyto1.split(',').map((email, index) => ({ name: email, id: index }))]}
                                        onDelete={(e) => hDeleteNotify(e, "warn")}
                                    />
                                </div>
                            </div>
                            <div className='Notifications_settings_mail_element_add'>
                                <InputText
                                    label="E-MAIL"
                                    attrs={{
                                        name: "email_warn",
                                        value: email_warn,
                                    }}
                                    onChange={hChangeEmail}
                                />
                                <ButtonCircle
                                    disabled={email_warn !== "" ? false : true}
                                    onClick={() => hUpdateNotify("warn")}
                                >
                                    <SVGPlus />
                                </ButtonCircle>
                            </div>


                        </div>

                        <div className="Notifications_settings_mail_element">
                            <div className='Notifications_settings_mail_element_tags'>
                                <h4>Уведомление о завершении трансляции рекламного материала:</h4>
                                <div className='Notifications_settings_mail_element_tags_items'>
                                    <Tags
                                        tags={notifyto2 !== "" && notifyto2 !== null && [...notifyto2.split(',').map((email, index) => ({ name: email, id: index }))]}
                                        onDelete={(e) => hDeleteNotify(e, "notify")}
                                    />
                                </div>
                            </div>
                            <div className='Notifications_settings_mail_element_add'>
                                <InputText
                                    label="E-MAIL"
                                    attrs={{
                                        name: "email_notify",
                                        value: email_notify,
                                    }}
                                    onChange={hChangeEmail}
                                ></InputText>
                                <ButtonCircle
                                    disabled={email_notify !== "" ? false : true}
                                    onClick={() => hUpdateNotify("notify")}
                                >
                                    <SVGPlus />
                                </ButtonCircle>
                            </div>
                        </div>
                        <h2>PUSH-УВЕДОМЛЕНИЕ ОТ СИСТЕМЫ ИНТЕЛЛЕКТУАЛЬНОГО КОНТРОЛЯ ЗВУКА</h2>
                        <Tabs
                            tabs={[
                                {
                                    title: "Настройка",
                                    content:

                                        <div className='Notifications_settings_list_container'>
                                            {!!updatedList.map && updatedList.map((el, index) => rolesMap[el.owner_id] ?
                                                <div className='Notifications_settings_mail_element_add'>

                                                    <div className='Notifications_add_push_role'>
                                                        <Select
                                                            // label={rolesMap[el.owner_id].title}
                                                            label="Роль"
                                                            type="options"
                                                            placeholder="Выберите роль"
                                                            selected={el.owner_id}
                                                            disabled
                                                            options={roles}
                                                            onChange={({ value }) => setUpdatedList((oldValue) => oldValue.map((object, _index) => index !== _index ? object : ({ ...object, owner_id: value })))}
                                                            onChangeInput={() => { }}
                                                        />
                                                    </div>
                                                    <div className='Notifications_add_push_tags' style={{ marginLeft: 36 }}>

                                                        <Select
                                                            label="Теги"
                                                            type="options"
                                                            placeholder="Название новой метки"
                                                            multiple={true}
                                                            selected={el.tags}


                                                            options={tags}
                                                            onChange={({ value }) => setUpdatedList((oldValue) => oldValue.map((object, _index) => index !== _index ? object : ({ ...object, tags: value })))}
                                                            onChangeInput={() => { }}
                                                        />
                                                    </div>
                                                    <div style={{ marginLeft: 24 }}>
                                                        <Button
                                                            mode="extend"
                                                            onClick={() => updateNotificationsTags(el)}
                                                            disabled={JSON.stringify(currentList[index]) === JSON.stringify(updatedList[index])}
                                                        >
                                                            <SvgSave />
                                                        </Button>
                                                    </div>
                                                    <div style={{ marginLeft: 24 }}>
                                                        <Button
                                                            type="string"
                                                            onClick={() => deleteNotificationsTags(el.owner_id)}
                                                        >
                                                            <SvgTrash title="Удалить" />
                                                        </Button>
                                                    </div>
                                                </div> : null)}
                                        </div>,
                                    afterContent:
                                        <>
                                            <h2>Добавить роль</h2>

                                            <div className='Notifications_add_push_container'>
                                                <div className='Notifications_add_push'>

                                                    <div className='Notifications_add_push_role'>
                                                        <Select
                                                            label={"Роли"}
                                                            type="options"
                                                            placeholder="Выберите роль"

                                                            selected={newObject.owner_id}


                                                            options={selectableRoles}
                                                            onChange={({ value }) => setNewObject(oldValue => ({ ...oldValue, owner_id: value }))}
                                                            onChangeInput={() => { }}
                                                        />
                                                    </div>
                                                    <div className='Notifications_add_push_tags' style={{ marginRight: 90 }}>
                                                        <Select
                                                            label={"Теги"}
                                                            type="options"
                                                            placeholder="Название новой метки"
                                                            multiple={true}
                                                            selected={newObject.tags}


                                                            options={tags}
                                                            onChange={({ value }) => setNewObject(oldValue => ({ ...oldValue, tags: value }))}
                                                        />
                                                    </div>

                                                </div>
                                                <div style={{ width: '150px' }}>
                                                    <ButtonBS onClick={() => {
                                                        addNotificationsTags(newObject)
                                                        setNewObject({ owner_id: null, tags: [] })
                                                    }} title='Добавить' disabled={!newObject.owner_id}>
                                                        Добавить
                                                    </ButtonBS>
                                                </div>
                                            </div>
                                        </>,


                                },
                                {
                                    title: "История уведомлений",
                                    content: <>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '10px', marginBottom: '10px', borderBottom: "2px solid #ddd" }}>
                                            <h4 style={{ width: '25%' }}>
                                                Объект
                                            </h4>
                                            <div style={{ width: '25%', textAlign: 'center' }}>
                                                ID устройства
                                            </div>
                                            <div style={{ width: '25%', textAlign: 'center' }}>
                                                Статус
                                            </div>

                                            <div style={{ width: '25%', textAlign: 'center' }}>
                                                Дата и Время
                                            </div>

                                        </div>
                                        <div className='Notifications_settings_list_container'>

                                            {!history.list.length && history.objects.length &&
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
                                                    <h4 style={{ width: '100%', textAlign: 'center' }}>
                                                        Уведомлений нет
                                                    </h4>

                                                </div>}


                                            {history.list.map(notification =>

                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '10px', borderBottom: "2px solid #ddd" }}>
                                                    <h4 style={{ width: '25%' }}>
                                                        {notification.ms_id} {history.objects[notification.ms_id]}
                                                    </h4>
                                                    <div style={{ width: '25%', textAlign: 'center' }}>
                                                        {notification.device}
                                                    </div>
                                                    <div style={{ width: '25%', textAlign: 'center' }}>
                                                        {notification.status}
                                                    </div>

                                                    <div style={{ width: '25%', textAlign: 'center' }}>
                                                        {moment(notification.date).format('HH:mm DD-MM-YY')}
                                                    </div>

                                                </div>
                                            )}
                                        </div>
                                    </>

                                }
                            ]}
                        />
                        {/* {
                            notificationPage === 'settings' &&
                            <>
                                <div className='Notifications_settings_list_container'>
                                    {!!updatedList.map && updatedList.map((el, index) => rolesMap[el.owner_id] ?
                                        <div className='Notifications_settings_mail_element_add'>

                                            <div className='Notifications_add_push_role'>
                                                <Select
                                                    // label={rolesMap[el.owner_id].title}
                                                    label="Роль"
                                                    type="options"
                                                    placeholder="Выберите роль"
                                                    selected={el.owner_id}
                                                    disabled
                                                    options={roles}
                                                    onChange={({ value }) => setUpdatedList((oldValue) => oldValue.map((object, _index) => index !== _index ? object : ({ ...object, owner_id: value })))}
                                                    onChangeInput={() => { }}
                                                />
                                            </div>
                                            <div className='Notifications_add_push_tags' style={{ marginLeft: 36 }}>

                                                <Select
                                                    label="Теги"
                                                    type="options"
                                                    placeholder="Название новой метки"
                                                    multiple={true}
                                                    selected={el.tags}


                                                    options={tags}
                                                    onChange={({ value }) => setUpdatedList((oldValue) => oldValue.map((object, _index) => index !== _index ? object : ({ ...object, tags: value })))}
                                                    onChangeInput={() => { }}
                                                />
                                            </div>
                                            <div style={{ marginLeft: 24 }}>
                                                <Button
                                                    mode="extend"
                                                    onClick={() => updateNotificationsTags(el)}
                                                    disabled={JSON.stringify(currentList[index]) === JSON.stringify(updatedList[index])}
                                                >
                                                    <SvgSave />
                                                </Button>
                                            </div>
                                            <div style={{ marginLeft: 24 }}>
                                                <Button
                                                    type="string"
                                                    onClick={() => deleteNotificationsTags(el.owner_id)}
                                                >
                                                    <SvgTrash title="Удалить" />
                                                </Button>
                                            </div>
                                        </div> : null)}
                                </div>
                                <div className='Notifications_add_push_container'>
                                    <div className='Notifications_add_push'>

                                        <div className='Notifications_add_push_role'>
                                            <Select
                                                label={"Роли"}
                                                type="options"
                                                placeholder="Выберите роль"

                                                selected={newObject.owner_id}


                                                options={selectableRoles}
                                                onChange={({ value }) => setNewObject(oldValue => ({ ...oldValue, owner_id: value }))}
                                                onChangeInput={() => { }}
                                            />
                                        </div>
                                        <div className='Notifications_add_push_tags' style={{ marginRight: 80 }}>
                                            <Select
                                                label={"Теги"}
                                                type="options"
                                                placeholder="Название новой метки"
                                                multiple={true}
                                                selected={newObject.tags}


                                                options={tags}
                                                onChange={({ value }) => setNewObject(oldValue => ({ ...oldValue, tags: value }))}
                                            />
                                        </div>

                                    </div>
                                    <div style={{ width: '150px' }}>
                                        <ButtonBS onClick={() => {
                                            addNotificationsTags(newObject)
                                            setNewObject({ owner_id: null, tags: [] })
                                        }} title='Добавить' disabled={!newObject.owner_id}>
                                            Добавить
                                        </ButtonBS>
                                    </div>
                                </div>
                            </>} */}

                    </div>
                </div>
                :
                <Spinner />
            }
        </div >
    )
}

const stateToProps = state => ({
    user: state.login.user,
    subusers: state.settings_roles.subusers,
    roles: state.settings_roles.roles,
    history: state.settings_notifications.history,
    currentList: state.settings_notifications.currentList,
    updatedList: state.settings_notifications.updatedList,
    notifyto1: state.settings_notifications.notifications?.notifyto1,
    notifyto2: state.settings_notifications.notifications?.notifyto2,
    loading: state.settings_notifications.loading,
    notifications: state.settings_notifications.notifications,
    tags: state.settings_notifications.tags
})

const actionsToProps = {
    loadNotifications,
    updateNotifications,
    loadRoles,
    loadNotificationHistory,
    addNotificationsTags,
    deleteNotificationsTags,
    updateNotificationsTags
}

export default connect(stateToProps, actionsToProps)(SettingsNotifications)