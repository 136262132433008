import React from 'react'
import { connect } from 'react-redux'

import { loadObjects, toggleSelectedObject } from '../../store/Objects/actions'
import { toggleSelectedObjectState } from '../../store/Reports/actions'

import Objects from './Objects'


const ObjectsContainer = props => (
    <Objects
        className={ props.className }
        types={ props.types }

        allObjects={ props.allObjects }
        trackObjects={ props.trackObjects }

        selectedObjects={ props.selectedObjects }
        selectedTracks={ props.selectedTracks }

        loading={ props.loading }

        loadObjects={ props.loadObjects }
        toggleSelectedObject={ props.toggleSelectedObject }
        toggleSelectedObjectState={ props.toggleSelectedObjectState }
    />
)

const stateToProps = state => ({
    allObjects: state.search["objects"].founded?.length ? state.search["objects"].founded :  state.objects.allObjects,
    trackObjects: state.objects.trackObjects,    
    selectedObjects: [...state.objects.selected],
    selectedTracks: state.tracks.selected,
    loading: state.objects.loading,
    searchActive: state.search["objects"].modeActive,
})

const actionsToProps = {
    loadObjects,
    toggleSelectedObject,
    toggleSelectedObjectState
}

export default connect(stateToProps, actionsToProps)(ObjectsContainer)