import {takeEvery, call, put, select, takeLeading} from 'redux-saga/effects'
import {LOAD_ROLES, loading, putRoles, putSubusers} from '../../store/Roles/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeLeading(LOAD_ROLES, worker)
}

function* worker() {
    yield put(loading(true))
    console.log({LOAD_ROLES});
    
    try {
        let response = yield call(fetchRoles)
        let currentUser = yield select(state => state.login.user);
        console.log({LOAD_ROLES},1);
        if (response.error && response.error.length) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_ROLES}.`, response],
                text: "Пользователи не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putRoles([]))
        } else {
            const roles = Object.values(response.success);
            yield put(putRoles({ roles }));
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_ROLES}.`, e],
            text: "Роли не были загружены." + e.message
        }))
        yield put(putRoles([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchRoles() {
    return axios.get("/account/roles/select")
        .then(response => response.data)
}
