import { takeEvery, call, put, select } from 'redux-saga/effects'
import { deleteTagsObject, DELETE_NOTIFICATIONS_TAGS, loading, putNotifications, putTags } from '../../store/Notifications/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(DELETE_NOTIFICATIONS_TAGS, worker)
}

function* worker({ payload }) {
    try {
        const tagsInsert = yield call(fetchDelete, payload)


        if (tagsInsert.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${DELETE_NOTIFICATIONS_TAGS}.`, tagsInsert],
                text: "Настройки не были удалены. Попробуйте перезагрузить страницу."
            }))
        } else {

            yield put(deleteTagsObject(payload))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${DELETE_NOTIFICATIONS_TAGS}.`, e],
            text: "Настройки не были удалены. Попробуйте перезагрузить страницу."
        }))
    }
    finally {
    }
}

function fetchDelete(id) {
    return axios.get("/push/delete?owner_id=" + id,)
        .then(response => response.data)
}


