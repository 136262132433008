import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

import { isFunc } from '../../../utilities/isFunc'


class InputText extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: this.props.attrs?.value || ""
        }

        this.hChange = this.hChange.bind(this)
        this.hClick = this.hClick.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (typeof this.props.attrs === "object") {
            if (prevProps.attrs.value !== this.props.attrs.value) {
                this.setState({
                    value: this.props.attrs.value
                })
            }
        }
    }

    hChange(event) {
        const value = event.target.value

        const continueToSave =
            (typeof this.props.validate === "function")
                ? this.props.validate(value)
                : true

        if (continueToSave) {
            this.setState({ value }, () => {
                const eventData = {
                    name: this.props.attrs?.name,
                    value: value,
                }
                if (typeof this.props.onChange === "function")
                    this.props.onChange(eventData, event)
            })
        }
    }

    hClick(event) {
        if (isFunc(this.props.onClick)) {
            this.props.onClick(event)
        }
    }

    render() {
        let {
            className = "",
            label,
            comment,
            createRef,
            icon,
            attrs = {},
            leftText
        } = this.props
        const {
            value
        } = this.state

        return (
            <div className={`FormInputText ${className}`}>
                <Form.Group controlId={attrs.id}>
                    {label
                        && <Form.Label>
                            {label}
                        </Form.Label>
                    }

                    {leftText ?
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <span style={{
                                marginTop: 8,
                                fontWeight: 500,
                                color: ' rgba(10, 10, 10, 0.72);',
                                borderWidth: 1
                            }}>{leftText}-</span>
                            <Form.Control
                                {...attrs}
                                ref={createRef}
                                type="text"
                                value={value}
                                onChange={this.hChange}
                                onClick={this.hClick}
                                onKeyDown={this.props.onKeyDown}
                            />
                        </div>
                        :
                        <Form.Control
                            {...attrs}
                            ref={createRef}
                            type="text"
                            value={value}
                            onChange={this.hChange}
                            onClick={this.hClick}
                            onKeyDown={this.props.onKeyDown}
                        />

                    }
                    {icon && icon}
                    {comment
                        && <div className="FormInputText__comment">
                            {comment}
                        </div>
                    }
                </Form.Group>
            </div>
        )
    }
}

InputText.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    comment: PropTypes.string,
    icon: PropTypes.element,
    attrs: PropTypes.object,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    validate: PropTypes.func,
    // createRef -- ссылка
}

export default InputText