import { takeEvery, call, put, select } from 'redux-saga/effects'
import { addTagsObject, INSERT_NOTIFICATIONS_TAGS, loading, putNotifications, putTags } from '../../store/Notifications/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(INSERT_NOTIFICATIONS_TAGS, worker)
}

function* worker({ payload }) {
    console.log({payload});
    
    try {
        const tagsInsert = yield call(fetchInsert, payload)


        if (tagsInsert.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${INSERT_NOTIFICATIONS_TAGS}.`, tagsInsert],
                text: "Настройки не были сохранены. Попробуйте перезагрузить страницу."
            }))
        } else {

            yield put(addTagsObject(payload))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${INSERT_NOTIFICATIONS_TAGS}.`, e],
            text: "Настройки не были сохранены. Попробуйте перезагрузить страницу."
        }))
    }
    finally {
    }
}

function fetchInsert(data) {
    let formData = new FormData()
    formData.set('owner_id', data.owner_id);
    formData.set('tags', data.tags);

    
    return axios.post("/push/insert", formData)
        .then(response => response.data)
}


