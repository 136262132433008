export const LOAD_NOTIFICATIONS = "Settings/Notifications/LOAD_NOTIFICATIONS"

export const INSERT_NOTIFICATIONS_TAGS = "Settings/Notifications/INSERT_NOTIFICATIONS_TAGS"
export const UPDATE_NOTIFICATIONS_TAGS = "Settings/Notifications/UPDATE_NOTIFICATIONS_TAGS"
export const DELETE_NOTIFICATIONS_TAGS = "Settings/Notifications/DELETE_NOTIFICATIONS_TAGS"

export const LOADING = "Settings/Notifications/LOADING"

export const PUT_NOTIFICATIONS = "Settings/Notifications/PUT_TEMPLATES"
export const PUT_TAGS = "Settings/Notifications/PUT_TAGS"
export const ADD_TAGS = "Settings/Notifications/ADD_TAGS"
export const REMOVE_TAGS = "Settings/Notifications/REMOVE_TAGS"
export const UPDATE_TAG = "Settings/Notifications/UPDATE_TAG"

export const UPDATE_NOTIFICATIONS = "Settings/Notifications/UPDATE_TEMPLATES"


export const LOAD_NOTIFICATION_HISTORY = "Settings/Notifications/LOAD_NOTIFICATION_HISTORY"
export const PUT_NOTIFICATION_HISTORY = "Settings/Notifications/PUT_NOTIFICATION_HISTORY"
export const ADD_NOTIFICATION_HISTORY = "Settings/Notifications/ADD_NOTIFICATION_HISTORY"

export const loadNotifications = () => ({
    type: LOAD_NOTIFICATIONS,
})

export const loading = mode => ({
    type: LOADING,
    payload: mode
})

export const putNotifications = notifications => ({
    type: PUT_NOTIFICATIONS,
    payload: notifications
})

export const putTags = payload => ({
    type: PUT_TAGS,
    payload
})

export const addNotificationsTags = payload => ({
    type: INSERT_NOTIFICATIONS_TAGS,
    payload
})

export const updateNotificationsTags = payload => ({
    type: UPDATE_NOTIFICATIONS_TAGS,
    payload
})

export const deleteNotificationsTags = payload => ({
    type: DELETE_NOTIFICATIONS_TAGS,
    payload
})

export const addTagsObject = payload => ({
    type: ADD_TAGS,
    payload
})

export const updateTagsObject = payload => ({
    type: UPDATE_TAG,
    payload
})

export const deleteTagsObject = payload => ({
    type: REMOVE_TAGS,
    payload
})


export const updateNotifications = notifications => ({
    type: UPDATE_NOTIFICATIONS,
    payload: notifications
})



export const loadNotificationHistory = () => ({
    type: LOAD_NOTIFICATION_HISTORY
})


// export const loadNextNotificationHistory = payload => ({
//     type: PUT_NOTIFICATION_HISTORY,
//     payload
// })


export const putNotificationHistory = payload => ({
    type: PUT_NOTIFICATION_HISTORY,
    payload
})


export const addNotificationHistory = payload => ({
    type: ADD_NOTIFICATION_HISTORY,
    payload
})

