import { takeEvery, call, put } from 'redux-saga/effects'
import { LOAD_NOTIFICATIONS, loading, putNotifications, putTags } from '../../store/Notifications/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(LOAD_NOTIFICATIONS, worker)
}

function* worker() {
    yield put(loading(true))
    try {
        const tags = yield call(fetchNotificationsTags)
        const push = yield call(fetchNotificationsUsed)
        console.log({push});
        
        if (tags.error && push.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${LOAD_NOTIFICATIONS}.`, tags],
                text: "Шаблоны не были загружены. Попробуйте перезагрузить страницу."
            }))
            yield put(putTags([]))
        } else {
            yield put(putTags({tags: tags.success, push: push.success}))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${LOAD_NOTIFICATIONS}.`, e],
            text: "Шаблоны не были загружены. Попробуйте перезагрузить страницу."
        }))
        yield put(putTags([]))
    }
    finally {
        yield put(loading(false))
    }
}

function fetchNotificationsTags() {
    return axios.get("/push/tags/select")
        .then(response => response.data)
}

function fetchNotificationsUsed() {
    return axios.get("/push/select")
        .then(response => response.data)
}


