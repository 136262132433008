import { DO_LOGOUT } from '../Login/actions'
import {
    ADD_TAGS,
    LOAD_NOTIFICATIONS, LOADING,
    PUT_NOTIFICATION_HISTORY,
    PUT_NOTIFICATIONS,
    PUT_TAGS,
    REMOVE_TAGS,
    UPDATE_NOTIFICATIONS,
    UPDATE_TAG
} from './actions'

const inititalState = {
    loading: false,
    notifications: [],
    tags: [],
    history: {
        objects: [],
        list: []
    },
    currentList: [],
    updatedList: {}
}

const reducer = (state = inititalState, { type, payload }) => {
    switch (type) {
        case LOAD_NOTIFICATIONS:
            return {
                ...state,
            }
        case PUT_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload.notifications[0],
            }
        case PUT_TAGS:
            return {
                ...state,
                tags: payload.tags,
                currentList: payload.push,
                updatedList: payload.push,
            }
        case ADD_TAGS:
            return {
                ...state,
                currentList: [...state.currentList, payload],

            }
        case UPDATE_TAG:
            return {
                ...state,
                currentList: state.currentList.map(el => el.owner_id === payload.owner_id ? payload : el),

            }
        case REMOVE_TAGS:
            return {
                ...state,
                currentList: state.currentList.filter(el => el.owner_id !== payload)

            }
        case UPDATE_NOTIFICATIONS:
            return {
                ...state,
            }

        case PUT_NOTIFICATION_HISTORY:
            return {
                ...state,
                history: payload
            }
        case LOADING:
            return {
                ...state,
                loading: payload
            }

        case DO_LOGOUT:
            return inititalState;
    }
    return state
}

export default reducer